.ui.grid .accordionDetails {
    margin-left: 2%;
}

.ui.negative.message {
    text-align: left;
}

.ui.grid.mainGrid {
    padding-left: 20%;
}

.segmentGrpWidth {
    width: 70%;
}

.firstSegment {
    display: inline-flex;
}

.actionButtons {
    width: 100%;
}