.voucherDetailSection {
    margin-right: 18px !important;
}

.redeemInfoSection {
    margin-left: 1.5em;
}

.accordionSection {
    margin-top: -5px;
    margin-bottom: -10px;
}