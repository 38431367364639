.font{
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}
.submenu:hover {
    background: #EF9A9A;
}

.menu:hover {
    background: rgba(255,255,255,.08);
}

.flex {
    flex: 1 0 auto
}
