.container {
    display:flex;
    flex-direction: row;
    align-items: center;
}
.nameCss {
    margin-top: 0;
    font-size: large;
    margin-left: 0.5em;
    font-weight: bold;
}

.iconCss {
    font-size: 18px !important;
}
