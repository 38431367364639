.voucherSection {
    margin-right: 18px !important;
}

.ui.form .inline.field .prompt, .ui.form .inline.fields .field .prompt {
    margin: 0.5em 0 -.5em .5em !important;
}

.paymentLinkModal {
    width: 25%;
}

.randomCheckboxField {
    padding-top: 10px;
}

.codeInput {
    padding-bottom: 20px;
    margin-top: 6px;
}

.requiredStyle {
    color: red;
}

.amtInputField {
    width: 50%;
}

.formGroup {
    width: 100%;
}

.salesPersonSection {
    margin-top: 10px;
}