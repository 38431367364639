.voucherSection{
    margin-right: 18px !important;
}

.statusWidth {
    width: 5%;
}

.tenPercentWidth {
    width: 10%;
}

.filterSection {
    float: left;
    padding-bottom: 10px;
}

.searchInput {
    margin-left: 20px;
}

.sortSection {
    float: right;
    padding-right: 2px;
}

.actionView {
    width: 5%;
}

.paginationSection {
    flex: 1;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.pageSizeSection {
    padding-top: 15px;
    margin-left: 2em;
    margin-right: 10px;
}

.pageSizeText {
    padding-top: 15px;
}

.orgNameText {
    font-size: initial;
}

.tableScrollbar {
    width: 100%;
    overflow-x: auto;
    margin-bottom: 10px;
}